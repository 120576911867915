import React from 'react';
import { Link } from 'react-router-dom';
import "./style.scss"

const ButtonContainer = (props) => {
  const { className, variant, font, ...rest } = props
  return (
    <button className={`btn line-22 ${font || ''} ${variant === 'lite' ? 'bg-lite' : 'bg-primary'} ${className || ''}`} {...rest} onClick={props.onClick} >
      {props.children}
    </button>
  )
}
const ButtonContent = (props) => {
  const { label, icon, Icon, iconColor, textFont, color, link, ...rest } = props
  const style = rest.disabled ? {} : { color: rest.variant === 'lite' ? color : '#FFF' }
  return (
    <>
      {Boolean(icon) && <i className={`${icon} icon ${Boolean(label) ? 'icon-text' : ''} ${iconColor || ''}`} />}
      {Boolean(Icon) && <Icon className={`icon ${Boolean(label) ? 'icon-text' : ''} ${iconColor || ''}`} />}
      {Boolean(label) && <span className={`label`} style={style}>{label}</span>}
    </>
  )
}
export const Button = (props) => {
  const { label, icon, iconColor, textFont, color, ...rest } = props
  const style = rest.disabled ? {} : { color: rest.variant === 'lite' ? color : '#FFF' }
  return (
    <ButtonContainer {...rest}>
      <ButtonContent {...props} />
    </ButtonContainer>
  )
}
export const LinkButton = (props) => {
  const { href, className, variant, font, link, disabled, label, color, ...rest } = props;
  const _className = `btn line-22 ${font || ''} ${disabled ? "btn-disabled" : (variant === 'lite' ? 'bg-lite' : 'bg-primary')} ${className || ''}`;
  return (
    Boolean(href) ?
      <a href={href} className={_className} {...rest}>
        <ButtonContent {...props} />
      </a>
      :
      <Link to={link} className={_className} {...rest}>
        <ButtonContent {...props} />
      </Link>
  )
}
