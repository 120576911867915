import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { MenuFoldOutlined, MenuUnfoldOutlined, DeleteOutlined } from '@ant-design/icons';
import { getActiveUsers, incidentActions } from "../../../store";
import { Form, Button, Modal } from "../../../components";

const ResponderFields = [
  { label: null, attribute: 'responder', placeholder: "Type Responder Name", type: 'select', allowSearch: true },
]

const AddResponder = (props) => {
  const { incident, open } = props;
  const dispatch = useDispatch()
  const [responder, setResponder] = React.useState('');
  const users = useSelector(getActiveUsers);
  React.useEffect(() => {
    if (open) {
      setResponder('');
    }
  }, [open])
  const handleSubmit = (e) => {
    e.preventDefault && e.preventDefault();
    if (responder) {
      const payload = { responderId: responder.id, incidentId: incident.id }
      dispatch(incidentActions.updateIncidentResponder(payload))
      props.onComplete && props.onComplete();
    }
    props.onClose && props.onClose();
  }
  const getOptions = () => {
    let options = [];
    for (let i = 0; i < users.length; i++) {
      const { id, _listname, department_name, designation } = users[i];
      let index = incident.incident_responders.findIndex(({ ent_usr_id }) => ent_usr_id === id);
      if (index == -1) {
        options.push({ id, label: `${_listname}, ${designation}, ${department_name}` })
      }
    }
    return options;
  }
  const handleResponderChange = (e) => {
    setResponder(e.target.value)
  }
  return (
    <Modal
      width={491}
      open={open}
      closeIcon={null}
      className=""
      actions={[
        { label: 'Cancel', variant: 'lite', className: 'gap min', color: '#0133CC', onClick: props.onClose },
        { label: 'Add', onClick: handleSubmit, disabled: !Boolean(responder) },
      ]}
      title={<span className="exo2 f24 c238787">Add Responder?</span>}>
      <div className="col w-100">
        <p className="f14 reg c00085 desc">This Responder will be able to access and update any information.<br />Search a Responder and confirm.</p>
        <Form
          className="row"
          formData={{ responder }}
          Fields={ResponderFields}
          getOptions={getOptions}
          onChange={handleResponderChange} />
      </div>
    </Modal>
  )
}

const DeleteResponderConfirm = (props) => {
  const dispatch = useDispatch()
  const { open, responder, incident } = props
  const handleDeleteConfirm = () => {
    const payload = { responderId: responder.ent_usr_id, incidentId: incident.id }
    dispatch(incidentActions.removeIncidentResponder(payload))
    props.onClose && props.onClose()
  }
  return (
    <Modal
      width={491}
      open={open}
      closeIcon={null}
      className=""
      actions={[
        { label: 'Cancel', className: 'gap min', onClick: props.onClose },
        { label: 'Delete', variant: 'lite', color: '#FE3333', onClick: handleDeleteConfirm, },
      ]}
      title={<span className="exo2 f24 cFE3333">Delete this Responder?</span>}>
      <div className="col w-100">
        <p className="f14 reg c00085 desc">{responder.responder_name} will not be able to access and update any information to this incident</p>
      </div>
    </Modal>
  )
}

export const IncideSideBar = (props) => {
  const { incident } = props;
  const [state, setState] = React.useState({ panelStatus: 'close', showAddResponder: false, showDelResponder: false });
  const isInvalid = incident && String(incident.status).trim().toLowerCase() === 'invalid';
  const handleToggleState = () => {
    let panelStatus = state.panelStatus === 'close' ? 'open' : 'close'
    setState((_) => ({ ..._, panelStatus: panelStatus }))
  }
  const handleAddResponder = (value) => {
    setState((_) => ({ ..._, showAddResponder: value }))
  };
  const handleDeleteResponder = (value) => {
    setState((_) => ({ ..._, showDelResponder: value }))
  }
  return (
    <div className={`col side-bar h-100 ${state.panelStatus}`}>
      <div className="row w-100 h-end">
        <button className="btn no-border" onClick={handleToggleState}>
          {
            state.panelStatus === 'close' ?
              <MenuUnfoldOutlined className="f24 c0133CC" />
              :
              <MenuFoldOutlined className="f24 c0133CC" />
          }
        </button>
      </div>
      <div className="col f-rest">
        <div className="col w-100 line-22 h-100 sidebar-container">
          <div className="row field w-100 f12 reg c00085">
            <span className="key-label">Incident ID:</span>
            <span className="col f-rest">{incident.incident_id}</span>
          </div>
          <div className="row field w-100 f12 reg c00085">
            <span className="key-label">Department:</span>
            <span className="col f-rest">{incident.department}</span>
          </div>
          <div className="row field w-100 f12 reg c00085 v-start">
            <span className="key-label">Reported By:</span>
            <div className="col f-rest">
              <span>{incident.creator_name}</span>
              <span className="f12">{incident.creator.designation}</span>
              <span className="f12">{incident.creator.department_name}</span>
            </div>
          </div>
          <div className="row field w-100 f12 reg c00085">
            <span className="key-label">Reported on:</span>
            <span className="col f-rest">{incident.created_at}</span>
          </div>
          <div className="row field w-100 f12 reg c00085 v-start">
            <div className="col key-label">
              <span>Responder:</span>
              {
                !isInvalid &&
                <Button
                  label='Add +' variant='lite'
                  onClick={handleAddResponder.bind(null, true)}
                  color='#0133CC' className='f10 btn-add-resp' />
              }
            </div>
            <div className="col f-rest">
              {
                incident.incident_responders.map((_) => {
                  return (
                    <div className="row w-100" key={_.ent_usr_id}>
                      <div className="col f-rest">
                        <span className="c0133CC">{_.responder_name}</span>
                      </div>
                      {
                        !isInvalid && incident.incident_responders.length > 1 &&
                        <Button
                          Icon={DeleteOutlined}
                          variant='lite'
                          iconColor='cc0000'
                          className='btn-resp-del'
                          onClick={handleDeleteResponder.bind(null, _)}
                        />
                      }

                    </div>
                  )
                })
              }
            </div>
          </div>
        </div>
      </div>
      <AddResponder
        incident={incident}
        open={state.showAddResponder}
        onComplete={props.onComplete}
        onClose={handleAddResponder.bind(null, false)}
      />
      <DeleteResponderConfirm
        incident={incident}
        open={Boolean(state.showDelResponder)}
        responder={state.showDelResponder}
        onClose={handleDeleteResponder.bind(null, false)}
      />
    </div>
  )
}