import "./saga";
export * from "./slice";

const getCommonState = (state) => state.common;

export const getDepartments = (state) => {
  const _state = getCommonState(state);
  return _state.departments
}
export const getDocClassifications = (state) => {
  const _state = getCommonState(state);
  return _state.documentClassification
}
export const getDocTypes = (state) => {
  const _state = getCommonState(state);
  return _state.documentTypes
}
export const getDepartmentResponders = (state) => {
  const _state = getCommonState(state);
  const depts = getDepartments(state);
  let deptResponders = _state.deptResponders;
  let responders = [];
  if (depts.length > 0) {
    responders = depts.map((_) => {
      let item = { departmentLabel: _.label, departmentId: _.id }
      let responder = deptResponders.find((resp) => (resp.department_name === _.id));
      if (responder) {
        item = { ...item, ...responder }
      }
      return item;
    })
  }
  return responders;
}
export const getDeptResponderByDept = (responders, dept, state) => {
  let list = Boolean(responders) ? responders : getDepartmentResponders(state);
  return list.find((_) => (_.departmentId === dept));
}
export const getIncidentReport = (incident) => {
  if (incident) {
    return incident.attachments.find(({ title }) => {
      return title === 'saved_incident_report'
    })
  }
  return null
}
