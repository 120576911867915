import React from 'react';
import { Dropdown } from 'antd';
import { usePub } from "../Event"
import { InvestigatorLogo, ProfilePic, CompanyLogo, AuditorIcon, ArcIcon, ConsoleIcon, ControllerIcon, EducatorIcon, DocumentorIcon, InvestigatorIcon, CentralIcon, ContinuerIcon } from "../Assets";
import { useNavigate, Link } from "react-router-dom";
import "./style.scss";

const DOMAIN = process.env.REACT_APP_MAIN_DOMAIN;

const Modules = [
  { moduleId: 'auditor', Component: AuditorIcon, label: 'Auditor' },
  { moduleId: 'arc', Component: ArcIcon, label: 'Arc' },
  { moduleId: 'console', Component: ConsoleIcon, label: 'Console' },
  { moduleId: 'controller', Component: ControllerIcon, label: 'Controller', isAdmin: true },
  { moduleId: 'documenter', Component: DocumentorIcon, label: 'Documenter' },
  { moduleId: 'educator', Component: EducatorIcon, label: 'Educator' },
  { moduleId: 'investigator', Component: InvestigatorIcon, label: 'Investigator' },
  { moduleId: 'central', Component: CentralIcon, label: 'Central' },
  { moduleId: 'continuer', Component: ContinuerIcon, label: 'Continuer' },
]
export const getModuleLink = (moduleId, user) => {
  const sessionToken = user.session_token;
  return `https://${moduleId}-sandbox.${DOMAIN}/login?sessionToken=${sessionToken}`;
}
export const NavBar = (props) => {
  const publish = usePub();
  const { user, isAdmin, isArchitect, currentModule } = props
  const navigate = useNavigate();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleLogout = (e) => {
    e.preventDefault();
    publish('SESSION_LOGOUT');
  }
  const getSubscribedModules = () => {
    let modules = Array.from(Modules);
    modules = modules.filter((mod) => {
      return mod.isAdmin ? (isAdmin || isArchitect) : mod.moduleId !== currentModule;
    })
    modules.sort((modA, modB) => {
      let moduleIdA = String(modA.moduleId).toLowerCase(),
        moduleIdB = String(modB.moduleId).toLowerCase();
      return moduleIdA.localeCompare(moduleIdB)
    })
    return modules
  }
  const ProfileDropdown = () => {
    return (
      <div className='col documenter-menus'>
        <div className='btn w-100 row btn-profile v-start no-border'>
          <ProfilePic className='profile-img' />
          <div className='col f-rest'>
            <div className='col w-100'>
              <span className='f18 reg usename'>{user.firstname} {user.lastname}</span>
              <span className='f12 reg c1155CC email'>{user.email}</span>
            </div>
            <div className='row h-btn profile-signout w-100'>
              <Link to={getModuleLink('console', user) + '&redirect=profile'} target='_blank' className='f14 profile' >Profile</Link>
              <Link className='f14 logout' onClick={handleLogout}>Sign Out</Link>
            </div>
          </div>
        </div>
        {
          getSubscribedModules().map(({ label, Component, moduleId }) => {
            return (
              <a className='row v-ctr f14 menu-entry link c00085' rel="noreferrer" key={moduleId} href={getModuleLink(moduleId, user)}>
                <Component className="mod-logo" />
                <span>{label}</span>
              </a>
            )
          })
        }
      </div>
    )
  }
  return (
    <nav className='row w-100 nav-bar h-btn'>
      <InvestigatorLogo className='logo' onClick={() => navigate('/')} />
      <div className='row'>
        <Dropdown
          overlayClassName="menu-overlay"
          dropdownRender={ProfileDropdown} placement="bottomRight" trigger={['click']} arrow>
          <div className='btn row btn-profile no-border'>
            <ProfilePic className='profile-img' />
            {/* <span className='f8 reg'>{user.firstname} {user.lastname}</span> */}
            <span className='f8'>
              <i className='c00085 icon-caret-down icon' />
            </span>
          </div>
        </Dropdown>
        <CompanyLogo user={user} />
      </div>
    </nav>
  )
}
export const useNavigateBack = () => {
  const navigate = useNavigate();
  const navigateBack = () => {
    if (window.history && window.history.state) {
      navigate(-1)
    } else {
      navigate('/')
    }
  }
  return navigateBack
}