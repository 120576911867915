import React, { forwardRef } from 'react';
import { Button } from "../Button";
import { Input } from "../Input";

const FilterSearchComponent = (props, ref) => {
  const { value, titleText } = props;
  return (
    <div className='col data-table-search'>
      <Input
        id='search'
        ref={ref}
        placeholder={`Search ${titleText}`}
        value={value || ''}
        onChange={props.onSearchChange} />
      <div className='row'>
        <Button label='Search' icon='icon-search' iconColor='cFFF' onClick={props.onSearch} />
        <Button label='Reset' className='reset' variant='lite' color='rgba(0, 0, 0, 0.85)' onClick={props.onReset} />
        <Button label='Filter' variant='lite' color='#0133CC' onClick={props.onFilter} />
        <Button label='Clear' variant='lite' color='#0133CC' onClick={props.onClear} />
      </div>
    </div>
  )
}
export const SearchComponent = forwardRef(FilterSearchComponent)