import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Modal, Form } from '../../../components';
import Fields from "./FormFields.json";
import { incidentActions, getDepartments, getSeverityTypes, getActiveUsers, getDepartmentResponders, getDeptResponderByDept, commonActions } from "../../../store";

const Actions = [
  { label: 'Cancel', variant: 'lite', className: 'min gap', color: '#0133CC' },
  { label: 'Create', },
]
const ResponderActions = [
  { label: 'Not Now', variant: 'lite', className: 'min gap', color: '#0133CC' },
  { label: 'Confirm', },
]

export const NewIncidentModal = (props) => {
  const { open } = props;
  const _ref = React.useRef(null)
  const dispatch = useDispatch();
  const departments = useSelector(getDepartments);
  const severities = useSelector(getSeverityTypes);
  const orgUsers = useSelector(getActiveUsers);
  const deptResponders = useSelector(getDepartmentResponders);
  const [state, setState] = React.useState({ incident: { department: null, responder: null } });
  const [responderPopup, setResponderPopup] = useState({
    showResponderPopup: false,
    isResponderManuallySet: false,
    department: '',
    responder: '',
  })
  React.useEffect(() => {
    if (!open) {
      setState((_) => ({ ..._, incident: {} }))
    }
  }, [open])

  const getActions = () => {
    return Actions.map((_, i) => {
      let action = { ..._ };
      action.onClick = (i == 0) ? props.onClose : handleSubmitNewIncident
      return action
    });
  }
  const getResponderPopupActions = () => {
    return ResponderActions.map((_, i) => {
      let action = { ..._ };
      action.onClick = (i == 0) ? handleResponderPopUpClose : handleResponderPopUpConfirm
      return action
    });
  }
  const getOptions = (attribute) => {
    let options;
    switch (attribute) {
      case 'department': options = departments; break;
      case 'severity':
        options = [];
        severities.forEach((_) => {
          if (String(_.id).toLowerCase() !== 'none') {
            options.push({ ..._, className: `severity ${String(_.id).toLowerCase()}` })
          }
        })
        break;
      case 'responder':
        options = orgUsers.map((_) => {
          return { label: `${_._listname}, ${_.designation}, ${_.department_name}`, id: _.id }
        })
        break;
      default: options = []; break;
    }
    return options
  }
  const handleSubmitNewIncident = (e) => {
    e.preventDefault && e.preventDefault();

    if (_ref.current && _ref.current.validate) {
      let valid = _ref.current.validate(e, true);
      if (!valid) {
        return
      }
    }
    const payload = new FormData();
    Fields.Incident.forEach(({ type, attribute }) => {
      let value = state.incident[attribute], key;
      if (attribute === 'documents') {
        if (value && value.length > 0) {
          value.forEach((file, i) => {
            payload.append(`${attribute}[evidence_${i + 1}]`, file);
          })
        }
      } else {
        if (type === 'select') {
          value = (value && value.id) ? value.id : value;
        }
        key = attribute === 'responder' ? 'responder[ent_usr_id]' : `incident[${attribute}]`
        payload.append(key, value)
      }
    });
    dispatch(incidentActions.createIncident(payload));
    props.onClose && props.onClose();
  }
  const handleValueChange = (e) => {
    const { name, value } = e.target;
    const popupdata = { ...responderPopup }
    const incident = { ...state.incident }
    incident[name] = value;

    if (value && name === 'department') {
      let deptResp = getDeptResponderByDept(deptResponders, value.id);
      if (deptResp.responder) {
        incident.responder = { label: deptResp.responder_listname, id: deptResp.responder.id }
      } else {
        incident.responder = null
        setResponderPopup((_) => ({ ..._, isResponderManuallySet: true }))
      }
    }
    if (value && name === 'responder' && (responderPopup.isResponderManuallySet)) {
      popupdata.showResponderPopup = true
      popupdata.department = incident.department
      popupdata.responder = incident.responder
      setResponderPopup(popupdata);
    }

    setState((_) => ({ ..._, incident: incident }));
  };

  const handleResponderPopUpClose = () => {
    setResponderPopup({
      showResponderPopup: false,
      isResponderManuallySet: false,
      department: '',
      responder: '',
    })
  }
  const handleResponderPopUpConfirm = () => {
    const payload = {
      department: responderPopup.department.id,
      userId: responderPopup.responder.id
    }
    dispatch(commonActions.updateDeptResponder(payload));
    setResponderPopup({
      showResponderPopup: false,
      isResponderManuallySet: false,
      department: '',
      responder: '',
    })
  }
  const handleDeleteFile = (attribute, index) => {
    const incident = state.incident;
    if (Array.isArray(incident[attribute]) && incident[attribute].length > 0) {
      incident[attribute].splice(index, 1)
    }
    setState((_) => ({ ..._, incident: { ...incident } }))
  }
  return (
    <>
      <Modal
        width={580}
        open={open}
        className="new-inc-modal"
        actions={getActions()}
        onCancel={props.onClose}
        title={<span className="exo2 f24 c238787">New Incident</span>}>
        {
          open &&
          <Form
            ref={_ref}
            onSubmit={handleSubmitNewIncident}
            className="form row fwarp h-btn"
            formData={{ ...state.incident }}
            Fields={Fields.Incident}
            getOptions={getOptions}
            onChange={handleValueChange}
            onDeleteFile={handleDeleteFile} />
        }
      </Modal>
      {
        responderPopup.showResponderPopup &&
        <Modal
          width={650}
          className='responder-popup'
          open={responderPopup.showResponderPopup}
          actions={getResponderPopupActions()}
          onCancel={handleResponderPopUpClose}
          title={<span className="exo2 f24 c238787">Update Responder Permanently?</span>}
        >
          <div className="f18 mt2">
            <div>Incident Responder for {responderPopup.department && responderPopup.department.label} is NOT defined.<br />Confirm whether Incident Responder list should be updated as well.</div>
            <div className="mt2">
              <div>Department: <b>{responderPopup.department && responderPopup.department.label || ''}</b></div>
              <div>Responder: <b>{responderPopup.responder && responderPopup.responder.label || ''}</b></div>
            </div>
            <hr className="hr-styled" />
          </div>
        </Modal>
      }
    </>
  )
}
