import React from "react";
import { PDFViewer, OvalLoading } from "../../../components";

export const IncidentReport = (props) => {
  const { report, zoom } = props;
  React.useEffect(() => {
    if (report) {
      let timer = setTimeout(() => {
        if (timer) {
          clearTimeout(timer)
        }
        initSectionLinks(false)
      }, 10000);
      return () => {
        initSectionLinks(true)
      }
    }
  }, [report])
  const handleContentLink = (e) => {
    let origin = window.location.origin;
    let href = e.target.href;
    if (href) {
      let index = e.target.href.indexOf(origin);
      if (index === -1) {
        window.open(e.target.href, '_blank');
      }
    }
    e.preventDefault();
  }
  const initSectionLinks = (remove) => {
    let allLinks = window.document.querySelectorAll('a');
    Array.prototype.map.call(allLinks, (link) => {
      if (remove) {
        link.removeEventListener('click', handleContentLink)
      } else {
        link.addEventListener('click', handleContentLink)
      }
    })
  }
  return (
    <div className="col f-rest o-hide bgWhite">
      {
        Boolean(report) ?
          <div className="col h-100 pdf-view-container inc-report">
            <PDFViewer {...report} zoom={zoom} />
          </div>
          :
          <OvalLoading />
      }
    </div>
  )
}