import React from 'react';
import { Modal as AntModal } from 'antd';
import { Button } from "../Button";
import "./style.scss";

const ModalFooter = (props) => {
  const { footeClassName, actions, CustomFooter } = props;
  return (
    Boolean(CustomFooter) ?
      <CustomFooter />
      : Array.isArray(actions) && actions.length > 0 ?
        <div className={`row ${footeClassName || 'h-end'}`}>
          {
            actions.map((action, i) => {
              return <Button {...action} key={i} />
            })
          }
        </div>
        : null
  )
}

export const Modal = (props) => {
  const { open, onClose, title, closeIcon, className, width } = props;
  return (
    <AntModal
      rootClassName='neu-modal'
      className={`${className || ''}`} width={width || 100} footer={<ModalFooter {...props} />}
      open={open} title={title} onCancel={onClose} closeIcon={closeIcon || null}>
      {props.children}
    </AntModal>
  )
}

export const TransparentDialog = (props) => {
  return (
    <Modal open className={`transparent-dialog ${props.className || ''}`}>
      <div className='dialog-container'>
        {props.children}
      </div>
    </Modal>
  )
}