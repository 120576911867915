import "./saga";
import { getDateInFormat } from "../app";
import password from "strong-password-generator";

export * from "./slice";

const DueDateMatch = { "Critical": 15, "High": 30, "Medium": 45, "Low": 90, "Minimal": 180, "Default": 45 }

var defaultPasswordConfig = {
  base: 'WORD',
  length: { min: 16, max: 16 },
  capsLetters: { min: 2, max: 3 },
  numerals: { min: 2, max: 3 },
  spacialCharactors: { includes: ["@", "_"], min: 2, max: 3 },
  spaces: { allow: false, min: 0, max: 0 }
};

const getIncidentState = (state) => state.incident;

export const getIncidents = (state) => {
  const _state = getIncidentState(state);
  return _state.incidents;
}
export const getSeverityTypes = (state) => {
  const _state = getIncidentState(state);
  return _state.severityTypes;
}
export const getStatusTypes = (state) => {
  const _state = getIncidentState(state);
  return _state.statusTypes;
}
export const getProrityTypes = (state) => {
  const _state = getIncidentState(state);
  return _state.priorityTypes;
}
export const getDateRangeEnums = (state) => {
  const _state = getIncidentState(state);
  return _state.dateRangeEnumns;
}
export const getIncidentTotalCount = (state) => {
  const _state = getIncidentState(state);
  return _state.totalIncidents;
}
export const getIncidentById = (incidentId, state) => {
  const incidents = getIncidents(state);
  let incident = (incidents || []).find(({ id, guid }) => (id === Number(incidentId) || guid === incidentId));
  return incident;
}
export const getCurrentViewIncident = (state) => {
  const _state = getIncidentState(state);
  return _state.viewIncident;
}
export const getSeverityTypeById = (severities, severity, state) => {
  let list = Array.isArray(severities) ? severities : getSeverityTypes(state);
  return list.find(({ id }) => (id === severity));
}
export const getStatusTypeById = (statusList, status, state) => {
  let list = Array.isArray(statusList) ? statusList : getStatusTypes(state);
  return list.find(({ id }) => (String(id).toLowerCase() === String(status).toLowerCase()));
}
export const getStatistics = (state) => {
  const _state = getIncidentState(state);
  return _state.statistics
}
export const getDueDateByPriority = (priority) => {
  return DueDateMatch[priority];
}
export const getReportPassword = (org) => {
  const orgFirstLetter = String(org.legalname)[0].toUpperCase();
  const suffix = `${orgFirstLetter}${getDateInFormat(undefined, 'YYMM')}RPR`;
  const randomPass = password.generatePassword(defaultPasswordConfig)
  return { suffix, pass: randomPass }
}
export const getZoomLevels = () => {
  return [
    { id: 0.5, label: '50%' },
    { id: 0.75, label: '75%' },
    { id: 1, label: '100%' },
    { id: "width", label: 'Page Width' },
    { id: 1.25, label: '125%' },
    { id: 1.5, label: '150%' },
    { id: 1.75, label: '175%' },
    { id: 2, label: '200%' },
  ]
}