import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getDepartmentResponders, commonActions, getActiveUsers } from "../../store";
import { DataTable, MenuContainer, Button, Form, Modal } from "../../components";

const Columns = [
  { title: 'Department', dataIndex: 'departmentLabel', key: 'department_label', width: '30%', className: 'f14', headerClassName: 'med' },
  { title: 'Responder', dataIndex: 'responder_name', key: 'responder_name', width: '65%', className: 'f14', headerClassName: 'med' },
  { title: '', dataIndex: 'action', key: 'action', width: '64px', render: 'btn', icon: 'icon-dot c00085', cellClassName: 'btn-menu bgTransparent' },
];

const ResponderFields = [
  { label: null, attribute: 'responder', placeholder: "Type Responder Name", type: 'select', allowSearch: true },
]

const ConfirmChangeModal = (props) => {
  const { open, department } = props
  return (
    <Modal
      width={517}
      open={open}
      closeIcon={null}
      className="change-res-modal"
      actions={[
        { label: 'Confirm', variant: 'lite', className: 'gap', color: '#0133CC', onClick: props.onConfirm },
        { label: 'Cancel', onClick: props.onClose },
      ]}
      title={<span className="exo2 f24 c238787">Confirm the Change…</span>}>
      <div className="col w-100">
        <p className="f14 reg c00085 desc">Confirm this change to the Incident Responder for the {department && department.departmentLabel} department.
          {
            Boolean(department && department.responder) &&
            <span><br />The previous and new Responders will be notified about this change.</span>
          }
        </p>
      </div>
    </Modal>
  )
}

export const ChangeResponderModal = (props) => {
  const { open, department } = props;
  const [state, setState] = React.useState({ selectedUser: null, showConfirmDialog: false })
  const users = useSelector(getActiveUsers);
  const isChange = Boolean(department && department.responder)
  React.useEffect(() => {
    if (!open) {
      setState((_) => ({ ..._, selectedUser: null }))
    }
  }, [open])
  const getUserOptions = () => {
    return users.map((_) => {
      const { _listname, id, department_name, designation } = _
      return { id: id, label: `${_listname}, ${designation}, ${department_name}` }
    });
  }
  const handleSubmit = (e, value) => {
    e.preventDefault();
    setState((_) => ({ ..._, showConfirmDialog: value !== undefined ? value : true }))
  }
  const handleConfirmChange = () => {
    props.onChange && props.onChange(state.selectedUser)
    setState((_) => ({ ..._, showConfirmDialog: false, selectedUser: null }))
  }
  const handleResponderChange = (e) => {
    setState((_) => ({ ..._, selectedUser: e.target.value }))
  }
  return (
    <React.Fragment>
      <Modal
        width={491}
        open={open}
        closeIcon={null}
        className="change-res-modal"
        actions={[
          { label: 'Cancel', variant: 'lite', className: 'gap min', color: '#0133CC', onClick: props.onClose },
          { label: isChange ? 'Update' : 'Assign', onClick: handleSubmit, disabled: !Boolean(state.selectedUser) },
        ]}
        title={<span className="exo2 f24 c238787">{isChange ? "Change" : 'Assign'} Responder?</span>}>
        <div className="col w-100">
          <p className="f14 reg c00085 desc">This Responder will become the default for {department && department.departmentLabel}.<br />All future incidents will be automatically assigned to this individual.</p>
          <Form
            onSubmit={handleSubmit}
            className="row"
            formData={{ responder: state.selectedUser }}
            Fields={ResponderFields}
            getOptions={getUserOptions}
            onChange={handleResponderChange} />
        </div>
      </Modal>
      <ConfirmChangeModal
        department={department}
        onConfirm={handleConfirmChange}
        onClose={(e) => handleSubmit(e, false)}
        open={state.showConfirmDialog}
      />
    </React.Fragment>
  )
}

export const IncidentResponder = (props) => {
  const dispatch = useDispatch();
  const [state, setState] = React.useState({ menuTarget: null, showChangeDialog: false, selectedDept: null })
  const deptResponders = useSelector(getDepartmentResponders);
  React.useEffect(() => {
    if (props.open) {
      dispatch(commonActions.fetchDeptResponders())
    }
  }, [props.open]);

  const getColumns = () => {
    let cols = Columns.map((_) => ({ ..._ }));
    cols[2].onClick = handleMenuClick
    return cols
  }
  const handleMenuClick = (e, record) => {
    setState((_) => ({ ..._, menuTarget: e.currentTarget, selectedDept: record }))
  }
  const handleSetDeptResponder = () => {
    setState((_) => ({ ..._, showChangeDialog: true, menuTarget: null }))
  }
  const handleChangeResponder = (user) => {
    const payload = { department: state.selectedDept.departmentId, userId: user.id }
    dispatch(commonActions.updateDeptResponder(payload))
    setState((_) => ({ ..._, showChangeDialog: false }));
  }
  return (
    <React.Fragment>
      <Modal
        width={551}
        open={props.open}
        className="inc-res-modal"
        onClose={props.onClose}
        closeIcon={<span className="f14 reg c0133CC">Close</span>}
        title={<span className="exo2 f24 c238787">Incident Responder</span>}>
        <DataTable
          noSelection
          Columns={getColumns()}
          rows={deptResponders}
        />
        <MenuContainer className='resp-menu' anchorEl={state.menuTarget} onClose={() => handleMenuClick({ currentTarget: null })}>
          <div className="col container f-rest">
            {
              Boolean(state.selectedDept) &&
              <React.Fragment>
                {
                  state.selectedDept.responder ?
                    <div className="col user-info">
                      <h6 className="f14 reg c00085 line-22 caps">{state.selectedDept.responder_name}</h6>
                      <span className="f12 reg c00073">{state.selectedDept.responder.designation}, {state.selectedDept.responder.department_name}</span>
                      <span className="f12 reg line-22 c000">{state.selectedDept.country_code_Num} {state.selectedDept.responder.mobile}</span>
                      <span className="f12 reg c0133CC line-22">{state.selectedDept.responder.email}</span>
                    </div>
                    :
                    <div className="col w-100 v-ctr h-ctr c777 f14 f-rest">Not Defined</div>
                }
              </React.Fragment>
            }
            <div className="row h-end w-100 footer">
              <Button variant='lite' label={Boolean(state.selectedDept && state.selectedDept.responder) ? 'Change' : 'Assign'} font='f14' color='#BB2222' onClick={handleSetDeptResponder} />
            </div>
          </div>
        </MenuContainer>
      </Modal>
      <ChangeResponderModal
        department={state.selectedDept}
        open={state.showChangeDialog}
        onChange={handleChangeResponder}
        onClose={() => setState((_) => ({ ..._, showChangeDialog: false }))}
      />
    </React.Fragment>
  )
}