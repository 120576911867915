import React, { useState, useRef, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { Table, Pagination } from 'antd';
import { Button } from "../Button";
import { OvalLoading } from "../Screens";
import { SearchComponent } from "./Search";
import { SearchOutlined } from '@ant-design/icons';
import Highlighter from 'react-highlight-words';
import "./style.scss"

const EmptyData = (props) => {
  const { height, message } = props
  return (
    <div className='col h-ctr v-ctr' style={{ height }}>
      <h6 className='exo2 f16 med c00085'>{message || 'No Data'}</h6>
    </div>
  )
}
const HeaderCell = (props) => {
  const { className, headerClassName, title } = props;
  return <span className={`${className || ''} ${headerClassName || ''}`} dangerouslySetInnerHTML={{ __html: title }}></span>
}
const CustomHighlighter = (props) => {
  const { searchText, text, ...rest } = props
  return (
    <Highlighter
      highlightStyle={{ backgroundColor: '#ffc069', padding: 0 }}
      searchWords={[searchText]}
      autoEscape
      textToHighlight={text ? text.toString() : ''}
      {...rest}
    />
  )
}
const DocumentTitle = (col, navigate, text, record, index) => {
  const { unlink, link, guid, id, linkTarget } = record;
  const targetLink = `${link || (guid || id)}`
  const className = `${col.cellClassName || 'f14'} line-22 c0133CC`;
  const handleClick = (e) => {
    e.preventDefault()
    if (linkTarget && linkTarget === "_blank") {
      window.open(e.target.href, "_blank")
    } else {
      navigate(targetLink)
    }
  }
  return (
    unlink ?
      Cell(col, text, record, index)
      :
      <Link to={targetLink} target={linkTarget} className={className} onClick={handleClick}>
        {
          col.searchedColumn === col.dataIndex ?
            <CustomHighlighter
              searchText={col.searchText}
              text={text}
            />
            :
            text
        }
      </Link>
  )
}
const HTMLCell = (col, text, record) => {
  const content = (text && typeof text === "object" && text.label) ? text.label : text
  return (
    col.searchedColumn === col.dataIndex ?
      <CustomHighlighter searchText={col.searchText} text={content} />
      :
      <p className='ck-container' dangerouslySetInnerHTML={{ __html: content }} ></p>
  )
}
const Cell = (col, text, record, index) => {
  const content = (text && typeof text === "object" && text.label) ? text.label : text;
  return (
    <span className={`${col.cellClassName || 'f14'} c00 line-22`} >
      {
        col.searchedColumn === col.dataIndex ?
          <CustomHighlighter searchText={col.searchText} text={content} />
          :
          content
      }
    </span>
  )
}
const ButtonCell = (col, text, record) => {
  const { icon, variant, color, onClick, cellClassName } = col;
  return <Button className={cellClassName} label={text} icon={icon} variant={variant} color={color} onClick={(e) => onClick && onClick(e, record)} />
}
const ColorCodeCell = (col, text) => {
  const content = (text && typeof text === "object" && text.label) ? text.label : text;
  const className = `${col.dataIndex} ${String(content || '').toLowerCase()}`
  return (
    <div className='row cell'>
      <div className={`cel-circle ${className}`}></div>
      <span className={`${col.cellClassName || 'f14'} ${className}-text c000 line-22 caps`}>
        {
          col.searchedColumn === col.dataIndex ?
            <CustomHighlighter searchText={col.searchText} text={content} />
            :
            content
        }
      </span>
    </div>
  )
}
const ColorTextCell = (col, text) => {
  const content = (text && typeof text === "object" && text.label) ? text.label : text;
  return (
    <span className={`${col.cellClassName || 'f14'} c000 line-22 caps ${col.dataIndex} ${String(content || '').toLowerCase()}`}>
      {
        col.searchedColumn === col.dataIndex ?
          <CustomHighlighter searchText={col.searchText} text={content} />
          :
          content
      }
    </span>
  )
}
const Sorter = (dataIndex, recordA, recordB) => {
  var nameA = recordA[dataIndex], nameB = recordB[dataIndex];
  nameA = ((typeof nameA === 'object' ? nameA.label : nameA) || '').toLowerCase();
  nameB = ((typeof nameB === 'object' ? nameB.label : nameB) || '').toLowerCase();
  if (nameA < nameB) {
    return -1;
  } else if (nameA > nameB) {
    return 1;
  }
  return 0;
}

const DefaultColumns = [
  { title: 'Version', dataIndex: 'version', key: 'version', width: '5%', render: Cell },
  { title: 'ID', dataIndex: 'id', key: 'id', width: '10%', render: Cell },
  { title: 'Document', dataIndex: 'title', key: 'title', width: '35%', render: DocumentTitle, search: true },
  { title: 'Department', dataIndex: 'department', key: 'department', width: '10%', render: Cell, search: true },
  { title: 'Status', dataIndex: 'status', key: 'status', width: '10%', render: ColorCodeCell, sorter: Sorter.bind(null, 'status') },
  { title: 'Clasification', dataIndex: 'clasification', key: 'clasification', width: '10%', render: ColorCodeCell },
  { title: 'Type', dataIndex: 'type', key: 'type', width: '10%', render: Cell, sorter: Sorter.bind(null, 'type') },
  { title: 'Last Updated', dataIndex: 'updated_at', key: 'updated_at', width: '10%', render: Cell },
]

export const DataTable = (props) => {
  const { rows, onSelectionChange, EmptyComponent, emptyProps, Columns, noSelection, scrollOffset, totalCount, defaultPage } = props;
  const [state, setState] = useState({ searchedColumn: null, searchText: '', height: 100, showfilterColumn: '' })
  const searchInput = useRef(null)
  const tableRef = useRef(null)
  const navigate = useNavigate()
  const handleServerSearch = (col, searchText) => {
    if (col.supportServerSearch) {
      props.onSearch && props.onSearch(col.dataIndex, searchText)
    }
  }
  const getFilterOptions = (column) => {
    let filterProps = {
      filterMultiple: false,
      filterDropdownOpen: state.showfilterColumn === column.dataIndex,
      filterDropdown: (({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
        return (
          <SearchComponent
            {...column}
            value={selectedKeys[0]}
            searchInput={searchInput}
            onSearchChange={(e) => {
              setSelectedKeys(e.target.value ? [e.target.value] : [])
            }}
            onFilter={() => {
              confirm();
              handleServerSearch(column, selectedKeys[0]);
              setState((_) => ({ ..._, searchText: selectedKeys[0], searchedColumn: column.dataIndex }))
            }}
            onReset={() => {
              setState((_) => ({ ..._, searchText: '' }))
              clearFilters();
            }}
            onClear={() => {
              handleServerSearch(column, '');
              setState((_) => ({ ..._, searchText: '', searchedColumn: null }))
              clearFilters();
              confirm();
            }}
            onSearch={() => {
              confirm({ closeDropdown: false });
              handleServerSearch(column, selectedKeys[0]);
              setState((_) => ({ ..._, searchText: selectedKeys[0], searchedColumn: column.dataIndex }))
            }}
          />
        )
      }),
      filterIcon: (filtered) => (
        <SearchOutlined style={filtered ? { color: '#1890ff' } : {}} />
      ),
      onFilterDropdownOpenChange: (visible) => {
        if (visible) {
          setState((_) => ({ ..._, showfilterColumn: column.dataIndex }))
          setTimeout(() => searchInput.current && searchInput.current?.focus(), 100);
        } else {
          setState((_) => ({ ..._, showfilterColumn: '' }))
        }
      }
    };
    if (!column.supportServerSearch) {
      filterProps.onFilter = (value, record) => {
        let attributeVal = record[column.dataIndex];
        attributeVal = (attributeVal && typeof attributeVal === "object") ? attributeVal.label : (attributeVal || '');
        return attributeVal.toString().toLowerCase().includes(value.toLowerCase())
      }
    }
    return filterProps;
  }
  const getColumns = () => {
    const columns = Columns || DefaultColumns;
    return columns.map((_) => {
      let col = { ..._ }
      col.titleText = col.title
      col.title = <HeaderCell {..._} />
      col.searchedColumn = state.searchedColumn;
      col.searchText = state.searchText
      if (col.render) {
        if (typeof col.render === "string") {
          switch (col.render) {
            case 'color-code': col.render = ColorCodeCell.bind(null, col); break;
            case 'color-text': col.render = ColorTextCell.bind(null, col); break;
            case 'link': col.render = DocumentTitle.bind(null, col, navigate); break;
            case 'btn': col.render = ButtonCell.bind(null, col); break;
            case 'html': col.render = HTMLCell.bind(null, col); break;
            default: col.render = Cell.bind(null, col);
          }
        }
      } else {
        col.render = Cell.bind(null, col)
      }
      if (col.sort) {
        col.sorter = Sorter.bind(null, col.dataIndex);
      }
      if (col.search) {
        col = { ...col, ...getFilterOptions(col) }
      }
      return col;
    })
  }
  useEffect(() => {
    if (tableRef && tableRef.current && Array.isArray(rows)) {
      let height = tableRef.current.clientHeight;
      if ((height) < 400) {
        height = rows.length === 0 ? 300 : 360
      }
      setState((_) => ({ ..._, height: height }))
    }
  }, [rows]);
  return (
    <div className='col w-100 h-100 table-ct o-hide' ref={tableRef} >
      {
        state.height &&
        <div className='col f-rest'>
          <Table
            className='doc-table'
            sticky
            locale={{
              emptyText: (
                rows === null ?
                  <OvalLoading isSmall />
                  : !Boolean(state.searchedColumn) && Boolean(EmptyComponent) ?
                    <EmptyComponent />
                    :
                    <EmptyData message={Boolean(state.searchedColumn) ? "No Matching Data" : props.emptyMessage} height={emptyProps && emptyProps.height ? emptyProps.height : state.height - 140} />
              )
            }}
            rowSelection={noSelection ? undefined : {
              type: 'checkbox',
              onChange: (selectedRowKeys, selectedRows) => {
                onSelectionChange && onSelectionChange(selectedRows)
              }
            }}
            scroll={{
              y: (state.height - (scrollOffset || (rows == null ? 0 : 100))),
            }}
            pagination={false}
            columns={getColumns()}
            dataSource={rows || []} />
        </div>
      }
      {
        totalCount > 0 &&
        <div className='col v-ctr h-ctr page-change'>
          <Pagination defaultCurrent={defaultPage || 1} pageSize={15} showSizeChanger={false} total={totalCount} onChange={props.onPageChange} />
        </div>
      }
    </div>
  )
}