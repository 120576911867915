import React from 'react';
import { OvalLoading } from "../Screens";
import { Viewer, Worker, SpecialZoomLevel } from '@react-pdf-viewer/core';
import { ZoomPlugin } from "./plugins/zoom.plugin";
import '@react-pdf-viewer/core/lib/styles/index.css';

export const PDFViewer = (props) => {
  const { evidence_url_full, blob, zoom } = props
  const [pdfContent, setPDFContent] = React.useState(null)
  const { zoomTo } = ZoomPlugin()
  React.useEffect(() => {
    return () => {
      if (blob && pdfContent) {
        URL.revokeObjectURL(pdfContent)
      }
    }
  }, [])
  React.useEffect(() => {
    setPDFZoom()
  }, [zoom])
  React.useEffect(() => {
    let contentURL
    if (blob) {
      contentURL = URL.createObjectURL(blob);
    } else {
      contentURL = evidence_url_full
    }
    if (contentURL) {
      setPDFContent(contentURL)
    }
    return () => {
      if (blob && pdfContent) {
        URL.revokeObjectURL(pdfContent)
      }
    }
  }, [blob])
  const setPDFZoom = () => {
    if (zoom) {
      if (zoom === 'width') {
        zoomTo(SpecialZoomLevel.ActualSize)
      } else {
        console.log('zoom level', zoom);
        zoomTo(zoom)
      }
    }
  }
  const handleOnLoad = () => {
    setPDFZoom()
  }
  return (
    <React.Fragment>
      {
        Boolean(pdfContent) ?
          <Worker workerUrl="https://unpkg.com/pdfjs-dist@2.16.105/build/pdf.worker.min.js">
            <Viewer fileUrl={pdfContent} plugins={[ZoomPlugin]} onDocumentLoad={handleOnLoad} />
          </Worker>
          :
          <OvalLoading isSmall />
      }
    </React.Fragment>
  )
}