import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStatistics, getDateRangeEnums, getDateInFullYearFormat, getDateInFormat, incidentActions } from "../../store";
import { Button, Form, OvalLoading, Modal } from "../../components";

const Field = { attribute: 'dateRange', type: 'select' }

const Columns = [
  { label: '', attribute: 'label', headerClassName: 'no-border', width: '114px' },
  { label: 'Max Aging <br/>(Days)', attribute: 'max_aging', headerClassName: 'c238787' },
  { label: 'Avg Aging <br/>(Days)', attribute: 'avg_aging', headerClassName: 'c238787' },
  { label: '<br/>OVERALL', attribute: 'overall', headerClassName: 'bg238787 cFFF' },
  { label: '<br/>Open', attribute: 'open', headerClassName: 'status open' },
  { label: '<br/>InProgress', attribute: 'inprogress', headerClassName: 'status inprogress' },
  { label: '<br/>Closed', attribute: 'closed', headerClassName: 'status closed' },
  { label: '<br/>Invalid', attribute: 'invalid', headerClassName: 'status invalid' },
]

const StatisticsTable = (props) => {
  const dateRangeEnumns = useSelector(getDateRangeEnums);
  const { rows, title, onDateChange, onExport } = props;
  const [dateRange, setDateRange] = React.useState('')
  React.useEffect(() => {
    if (dateRangeEnumns.length > 0) {
      setDateRange(dateRangeEnumns[6])
    }
  }, [dateRangeEnumns])
  React.useEffect(() => {
    onDateChange && onDateChange(dateRange);
  }, [dateRange])
  const getOptions = () => {
    return dateRangeEnumns
  }
  const handleFieldChange = (e) => {
    setDateRange(e.target.value)
  };
  return (
    <div className={`col w-100 ${props.className || ''}`}>
      <div className="row h-end">
        <Button
          className='row row-reverse btn-export'
          color='#0133CC'
          iconColor='c0133CC'
          font='f14'
          onClick={onExport && onExport.bind(null, dateRange)}
          variant='lite' label='Export' icon='icon-export' />
      </div>
      <div className="row h-btn">
        <div className="row f-rest">
          <div className="f16 bold c238787 title">{title}</div>
          <Form
            className='col'
            formData={{ dateRange }}
            Fields={[Field]}
            getOptions={getOptions}
            onChange={handleFieldChange} />
        </div>
        <span>(Stats as on {getDateInFullYearFormat()})</span>
      </div>
      {
        Array.isArray(rows) && rows.length > 0 ?
          <table className="stats-tbl">
            <thead>
              <tr>
                {
                  Columns.map((col) => {
                    return (
                      <th style={{ width: col.width || '100px' }} className={`f14 med cell ${col.headerClassName}`} dangerouslySetInnerHTML={{ __html: col.label }} ></th>
                    )
                  })
                }
              </tr>
            </thead>
            <tbody>
              {
                rows.map((row, i) => {
                  return (
                    <tr key={i}>
                      {
                        Columns.map((col) => {
                          let value = row[col.attribute];
                          let isLabelCell = col.attribute === 'label';
                          return (
                            <td key={col.attribute} className={`f14 reg cell ${isLabelCell ? row.labelClassName : 'c238787'}`}>
                              <div className={`row w-100 h-100 ${isLabelCell ? 'v-ctr' : 'h-ctr'} `}>
                                <span className="f14 bold">{value}</span>
                              </div>
                            </td>
                          )
                        })
                      }
                    </tr>
                  )
                })
              }
            </tbody>
          </table>
          :
          <OvalLoading />
      }
    </div>
  )
}

export const IncidentStatistics = () => {
  const [exportConfig, setExportConfig] = React.useState(null);
  const dispatch = useDispatch();
  const statistics = useSelector(getStatistics)
  React.useEffect(() => {
    dispatch(incidentActions.fetchDateRangeEnums());
  }, []);
  const handleFetchDepartmentStats = (dateRange) => {
    if (dateRange && dateRange.id) {
      dispatch(incidentActions.fetchDeptStats({ dateRangeType: dateRange.id }))
    }
  }
  const handleExport = (type, dateRangeType) => {
    const filename = `Fusion_Investigator_Stats_${type === 'dept' ? 'Department' : 'Severity'} ${getDateInFormat(undefined, "YYMMDD")}.png`;
    setExportConfig({ type, filename, dateRangeType: dateRangeType })
  }
  const handleFetchSeverityStats = (dateRange) => {
    if (dateRange && dateRange.id) {
      dispatch(incidentActions.fetchSeverityStats({ dateRangeType: dateRange.id }))
    }
  }
  const handleConfirmExport = () => {
    const { type, filename, dateRangeType } = exportConfig;
    if (type === 'dept') {
      dispatch(incidentActions.exportDeptStats({ filename, dateRangeType }))
    } else {
      dispatch(incidentActions.exportSeverityStats({ filename, dateRangeType }))
    }
    setExportConfig(null)
  }
  const getSeverityStats = () => {
    return statistics.severity.map((_) => {
      return { ..._, labelClassName: `severity ${String(_.id).toLowerCase()}` }
    })
  }
  const getDepartmentStats = () => {
    return statistics.department.map((_) => {
      return { ..._, labelClassName: `bg${_.id}` }
    })
  }
  return (
    <div className="col w-100 h-100 oy-auto">
      <div className="col inc-stats">
        <StatisticsTable
          className='gap'
          title='Severity-wise Status'
          rows={getSeverityStats()}
          onDateChange={handleFetchSeverityStats}
          onExport={handleExport.bind(null, 'severity')}
        />
        <StatisticsTable
          title='Department-wise Status'
          rows={getDepartmentStats()}
          onDateChange={handleFetchDepartmentStats}
          onExport={handleExport.bind(null, 'dept')}
        />
      </div>
      <Modal
        open={Boolean(exportConfig)}
        width={517}
        actions={[
          { label: 'Cancel', variant: 'lite', className: 'min gap', color: '#0133CC', onClick: () => setExportConfig(null) },
          { label: 'Export', onClick: handleConfirmExport },
        ]}
        title={<span className="exo2 f20 bold c238787">Export Stats?</span>}>
        <p className="f14 reg 00085">Stats will be exported as a PNG Image.<br />File Name: <span className="bold">{exportConfig && exportConfig.filename}</span></p>
      </Modal>
    </div>
  )
}