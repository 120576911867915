import React from "react";
import { useNavigate } from "react-router-dom";
import { Tabs } from 'antd';
import { useSelector, useDispatch } from "react-redux";
import { IncidentStatistics } from "./Statistics";
import { Button, Menu } from "../../components";
import { IncidentResponder } from "./Responder";
import { NewIncidentModal, IncidentTracker } from "./Incident";
import { getCurrentUser, isAdmin, isArchitect, isOrganizer, isAccessor, incidentActions } from "../../store";
import "./style.scss";

const MenuItems = [
  { dialogId: 'responders', label: "Incident Responders", className: '' }
]

const getTabItems = (isSuperUser) => {
  let tabs = [{ label: 'Tracker', key: 'T' }];
  if (isSuperUser) {
    tabs.push({ label: 'Stats', key: 'S' })
  }
  return tabs;
}

const getTab = () => {
  let currentUrlParams = new URLSearchParams(window.location.search);
  return currentUrlParams.get('tab') || 'T'
}

export const IncidentDashboardScreen = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const user = useSelector(getCurrentUser);
  const isSuperUser = isAdmin(user) || isArchitect(user) || isOrganizer(user);
  const TabItems = getTabItems(isSuperUser);
  const [state, setState] = React.useState({ tab: getTab(isSuperUser), menuTarget: null, selectedDialogId: null });
  React.useEffect(() => {
    dispatch(incidentActions.fetchSeverityTypes())
    dispatch(incidentActions.fetchStatusTypes())
  }, [])
  const handle3DotMenu = (e) => {
    setState((_) => ({ ..._, menuTarget: e.currentTarget }))
  }
  const handleMenuItemClick = (e, menu) => {
    setState((_) => ({ ..._, menuTarget: null, selectedDialogId: menu ? menu.dialogId : null }))
  }
  return (
    <div className='row v-start w-100 h-100 o-hide screen-pad bgWhite'>
      <div className='col f-rest h-100'>
        <div className="row w-100 h-btn">
          <Tabs
            items={TabItems}
            activeKey={state.tab}
            onChange={(key) => {
              setState((_) => ({ ..._, tab: key, selected: null }))
              navigate(`${window.location.pathname}?tab=${key}`)
            }} />
          {
            (state.tab === TabItems[0].key) &&
            <div className="row">
              <Button label="New Incident" className='f14 reg' onClick={(e) => handleMenuItemClick(e, { dialogId: 'new-incident' })} />
              {
                (isSuperUser || isAccessor(user)) &&
                <Button className='col v-ctr h-ctr btn-menu f8' icon="icon-dot cFFF" onClick={handle3DotMenu} />
              }
            </div>
          }
        </div>
        <div className='col f-rest  o-hide'>
          {
            (state.tab === TabItems[0].key) &&
            <IncidentTracker />
          }
          {
            Boolean(TabItems[1]) && (state.tab === TabItems[1].key && isSuperUser) &&
            <IncidentStatistics />
          }
        </div>
      </div>
      <IncidentResponder
        onClose={handleMenuItemClick}
        open={state.selectedDialogId === MenuItems[0].dialogId}
      />
      <NewIncidentModal
        onClose={handleMenuItemClick}
        open={state.selectedDialogId === 'new-incident'}
      />
      <Menu
        menuItems={MenuItems}
        anchorEl={state.menuTarget}
        onMenuClick={handleMenuItemClick}
        onClose={() => handle3DotMenu({ currentTarget: null })} />
    </div>
  )
}