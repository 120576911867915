import * as React from 'react';
import { createStore } from '@react-pdf-viewer/core';

export const ZoomPlugin = () => {
  const store = React.useMemo(() => createStore({}), []);

  return {
    install: (pluginFunctions) => {
      store.update('zoom', pluginFunctions.zoom);
    },
    zoomTo: (scale) => {
      const zoom = store.get('zoom');
      console.log("zoom", zoom);
      if (zoom) {
        // Zoom to that scale
        zoom(scale);
      }
    },
  };
};
