import React from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { DataTable } from "../../../components";
import { incidentActions, getIncidents, getIncidentTotalCount } from "../../../store";

const Columns = [
  { title: 'Incident ID', dataIndex: 'incident_id', key: 'incident_id', width: '160px', className: 'exo2 f14' },
  { title: 'Severity', dataIndex: 'severity', key: 'severity', width: '150px', className: 'exo2 f14 ', sort: true, render: 'color-code' },
  { title: 'Status', dataIndex: 'status', key: 'status', width: '150px', className: 'exo2 f14', sort: true, render: 'color-text', cellClassName: '' },
  { title: 'Incident Title', dataIndex: 'title', key: 'title', width: '50%', search: true, render: 'link', variant: 'lite', color: '#0033CC', className: 'exo2 f14', supportServerSearch: true },
  { title: 'Department', dataIndex: 'department', key: 'department', width: '15%', className: 'exo2 f14' },
  { title: 'Reported <span class="reg exo2">(24 Hr Format)</span>', dataIndex: 'created_at', key: 'create_at', width: '20%', className: 'exo2 f14 text-center' }
]
const getPage = () => {
  let currentUrlParams = new URLSearchParams(window.location.search);
  return currentUrlParams.get('page') || 1
}

export const IncidentTracker = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const incidents = useSelector(getIncidents);
  const incidentsCount = useSelector(getIncidentTotalCount);
  const _filter = React.useRef({ defaultPage: getPage(), pageNo: getPage(), search: '' })
  React.useEffect(() => {
    fetchIncidents();
  }, [])
  const fetchIncidents = () => {
    const { pageNo, search } = _filter.current;
    dispatch(incidentActions.fetchIncidents({ pageNo, search }))
    dispatch(incidentActions.setIncident({ incident: null }))
  }
  const handlePageChange = (pageNo) => {
    navigate(`${window.location.pathname}?page=${pageNo}`)
    _filter.current = { ..._filter.current, pageNo: pageNo }
    fetchIncidents()
  }
  const handleSearch = (colDataIndex, searchText) => {
    const search = { [colDataIndex]: searchText }
    _filter.current = { ..._filter.current, search };
    fetchIncidents();
  }
  return (
    <div className='col w-100 h-100 o-hide'>
      <DataTable
        noSelection
        emptyMessage="There are no incidents right now!"
        Columns={Columns}
        rows={incidents}
        totalCount={incidentsCount}
        onSearch={handleSearch}
        defaultPage={_filter.current.defaultPage}
        onPageChange={handlePageChange}
      />
    </div>
  )
}