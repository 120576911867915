import React from 'react';
import { BrowserRouter, Route, Routes, Navigate } from 'react-router-dom';
import { HomeScreen, LoginScreen, PageNotFoundScreen, IncidentDashboardScreen, ViewIncidentScreen } from "../screens";

const IncidentRouter = () => {
  return (
    <Routes>
      <Route path="/:incidentId" index element={<ViewIncidentScreen />} />
      <Route path="/" element={<IncidentDashboardScreen />} />
      <Route path="" element={<Navigate replace to="/" />} />
    </Routes>
  )
}


const MainRouter = () => {
  return (
    <HomeScreen>
      <Routes>
        <Route path="/I/*" index element={<IncidentRouter />} />
        <Route path="/" element={<Navigate replace to="/I" />} />
        <Route path="" element={<Navigate replace to="/" />} />
        <Route path="*" element={<Navigate replace to="/page-not-found" />} />
      </Routes>
    </HomeScreen>
  )
}


function AppRouter() {
  return (
    <BrowserRouter >
      <Routes>
        <Route path="/login" element={<LoginScreen />} exact />
        <Route path="/page-not-found" element={<PageNotFoundScreen />} exact />
        <Route path="/*" element={<MainRouter />} />
      </Routes>
    </BrowserRouter>
  );
}

export default AppRouter;
